/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import Layout from "../../components/layout";
import Container from "../../components/container";
import {mainContent, introSection, buttonGroup, description, marginTopSubstitute, smallMarginTopSubstitute} from "../subpage.module.scss";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(Layout, {
    pageTitle: "News",
    neutralPage: true
  }, React.createElement("main", {
    className: mainContent
  }, React.createElement("section", {
    className: introSection
  }, React.createElement(Container, {
    mdxPageWidth: true
  }, React.createElement("div", {
    className: description
  }, "Fachartikel - 02. Mai 2024"), React.createElement(_components.h1, null, "Smart reicht nicht mehr, die Zukunft des digitalen Spitals ist «liquid»"), React.createElement("div", {
    className: "text-medium"
  }, React.createElement(_components.p, null, "Ein Artikel aus der Netzwoche. Eine Leseempfehlung von uns.")), React.createElement(_components.p, null, "Moderne Spitäler sollten ihre Patientinnen und Patienten über die Spitalgrenzen hinweg betreuen. Ein wesentlicher Baustein hierfür ist der sichere und nahtlose Datenfluss in den Spitälern und ­darüber hinaus. Als Lösung bietet sich eine cloudbasierte Integrationsplattform an."), React.createElement("div", {
    style: {
      marginBottom: 20
    },
    className: buttonGroup
  }, React.createElement("a", {
    href: "https://www.netzwoche.ch/news/2024-05-02/smart-reicht-nicht-mehr-die-zukunft-des-digitalen-spitals-ist-liquid",
    className: "button-outline blue",
    target: "_blank",
    rel: "noreferrer"
  }, " Zum Artikel "), " "), React.createElement("div", {
    style: {
      marginBottom: 40
    },
    className: buttonGroup
  }, React.createElement("a", {
    href: "https://future.hospital/?trk=public_post-text",
    className: "button-outline blue",
    target: "_blank",
    rel: "noreferrer"
  }, " Mehr zum Innosuise SHIFT Projekt "), " "), React.createElement(_components.p, null, "Bleiben Sie über neueste Informationen und Veranstaltungen von the i-engineers stets informiert und melden Sie sich für unseren Newsletter an."), React.createElement("div", {
    className: buttonGroup
  }, React.createElement("a", {
    href: "https://www.tie.ch/landingpages/newsletter-anmeldung",
    className: "button-outline blue",
    target: "_blank",
    rel: "noreferrer"
  }, " Zur Newsletter Anmeldung "), " ")))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
